import React from "react"
import Layout from "../components/Theme/layout"

const AboutPage = ({data}) => {
  return( 
    <Layout>
      <h1>About Page</h1>
    </Layout>
    )
}

export default AboutPage
